body,
html {
    height: 100%;
    overflow-x: hidden;
}

.flexcontainerquote {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 60%;
    /* height: 100%; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 1000;
    background: white;
    display: none;
}

@media(max-width:600px) {
    .flexcontainerquote {
        width: 100%;
        height: 75%;
    }
}

/* .flexcontainerquotesec {
    top: 200%;
    transform: translate(-50%, -80%);
} */

.flexcontainerframe {
    /* top: 200%;
    transform: translate(-50%, -80%); */
    height: 70%;
    width: 70%;
}

@media(max-width:600px) {
    .flexcontainerframe {

        height: 40%;
        width: 90%;
    }
}

.flexitemquote1 {
    flex: 60%;
    max-width: 60%;
    padding: 50px;
}

.flexitemquote2 {
    flex: 40%;
    max-width: 40%;
}

@media(max-width:1000px) {
    .flexitemquote1 {
        flex: 50%;
        max-width: 50%;
        padding: 0px;
        padding-top: 10px;
        padding-bottom: 10px;

    }

    .flexitemquote2 {
        flex: 50%;
        max-width: 50%;
    }
}

@media(max-width:600px) {
    .flexitemquote1 {

        padding: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        flex: 100%;
        max-width: 100%;
    }

    .flexitemquote2 {

        display: none;
    }
}

@media(min-width:1000px) {
    .flexitemquote1 {
        flex: 60%;
        max-width: 60%;
    }

    .flexitemquote2 {
        flex: 40%;
        max-width: 40%;
    }
}

@media(min-width:1500px) {
    .flexitemquote1 {
        flex: 70%;
        max-width: 70%;
    }

    .flexitemquote2 {
        flex: 30%;
        max-width: 30%;
    }
}

.flexitemquote2 img {
    width: 100%;
    height: 100%;
}

.flexform {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.widthquote {
    width: 270px;
}

.marginbottomquote {
    margin-bottom: 50px;
}

@media(max-width:600px) {

    .widthquote {
        width: 60px;
    }

    .marginbottomquote {
        margin-bottom: 0px;
    }
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    cursor: pointer;
}

.widthcalender {
    width: 50px;
}

.closebtn {
    position: fixed;
    right: 2rem;
    font-size: 60px;
    z-index: 999;
    top: 20px;
    outline: none;
    cursor: pointer;
    border: 0 !important;
    text-decoration: none !important;
    outline-style: none !important;
    display: none;
}

@media(max-width:600px) {
    .closebtn {
        top: -10px;
    }
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    .flexcontainerquote {
        height: 70%;
        overflow: auto;
    }

    .flexitemquote1 {

        padding: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        flex: 100%;
        max-width: 100%;
    }

    .flexitemquote2 {

        display: none;
    }

    .widthquote {
        width: 60px;
    }

    .marginbottomquote {
        margin-bottom: 0px;
    }

    .different {
        margin-bottom: 10px !important;
    }
}